import { useEffect, useState } from "react"
import getUrlParams from "./getUrlParams"

const useUrl = () => {
	const [state, setState] = useState(getUrlParams())

	useEffect(() => {
		setState(getUrlParams())
	}, [window.location.href])

	return state
}

export default useUrl
