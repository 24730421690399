/* eslint-disable no-undef */
/* eslint-disable indent */
import {
	LOGOUT,
	UPDATE_ORGANIZATION,
	UPDATE_PROFILE,
	UPDATE_UNREAD_MESSAGES,
	UPLOADING_STATUS_PROGRESS,
} from "../types"

const initialState = {
	profile: {},
	organization: null,
	unreadMessages: "",
}

export const userReducer = (state = initialState, action) => {
	const { type, payload } = action

	switch (type) {
		case UPDATE_PROFILE:
			return { ...state, profile: payload }
		case UPDATE_ORGANIZATION:
			return { ...state, organization: payload }
		case UPLOADING_STATUS_PROGRESS:
			return {
				...state,
				uploadingProgress: payload,
			}
		case LOGOUT:
			return initialState
		default:
			return state
		case UPDATE_UNREAD_MESSAGES:
			return {
				...state,
				unreadMessages: payload,
			}
	}
}
