/* eslint-disable react/display-name */
import React, { forwardRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { appLogout } from "../../../redux/actions/app"
import { ArrowDown, IconLogout, IconMessage, IconReports } from "../../icons"
import "./index.scss"

const Menu = forwardRef((props, ref) => {
	const dispatch = useDispatch()
	const state = useSelector(state => state)
	const [dropDown, setDropDown] = useState(false)

	const searchGetActiveClassName = reg => {
		let result = ""
		const arr = []
		reg.forEach(e => arr.push(location.search.match(e) ? true : false))

		if (!arr.includes(false)) {
			result = "_active"
		}

		return result
	}

	return (
		<div ref={ref} id="menu" className="d-flex flex-column jc-between scroll-y">
			<div className="menu">
				<div className="menu-block">
					<div className="name mb-3">Меню</div>
					<NavLink activeClassName="_active" to="/calendar" className="link">
						<IconReports className="pos-abs-y menu_img" />
						Календарь
					</NavLink>
					<NavLink activeClassName="_active" to="/events" className="link">
						<IconReports className="pos-abs-y menu_img" />
						Мероприятия
					</NavLink>
					<NavLink activeClassName="_active" to="/report/templates" className="link">
						<IconReports className="pos-abs-y menu_img" />
						Отчеты
					</NavLink>
					<div
						className="link jc-between d-flex"
						onClick={() => setDropDown(!dropDown)}
						style={{ cursor: "pointer" }}
					>
						<div>
							<span>
								{" "}
								<IconMessage className="pos-abs-y menu_img" />
							Сообщения{" "}
							</span>
							{!!state.user.unreadMessages && (
								<span className="msg-num f-300">
									{state.user.unreadMessages}
								</span>
							)}
						</div>
						{" "}
						<ArrowDown className={`icon ${dropDown && "transition-icon"}`} />
					</div>
					<div className={`drop-down ${dropDown && "_viewed"}`}>
						<NavLink
							activeClassName={searchGetActiveClassName([/from=2/g])}
							to="/mail?from=2"
							className="link"
						>
							Входящие
						</NavLink>
						<NavLink
							activeClassName={searchGetActiveClassName([/from=1/g])}
							to="/mail?from=1"
							className="link"
						>
							Исходящие
						</NavLink>
					</div>
				</div>

				<div className="menu-block">
					<span
						className="link cursor-pointer"
						onClick={() => dispatch(appLogout())}
					>
						<IconLogout className="pos-abs-y menu_img" />
						Выйти
					</span>
				</div>
			</div>

			{/*<div className="buttons mt-5">*/}
			{/*	<Link to="#" download className="btn btn-block btn-primary mt-2">*/}
			{/*		Скачать программы для отчетов*/}
			{/*	</Link>*/}
			{/*	<Link to="#" download className="btn btn-block btn-primary mt-2">*/}
			{/*		Алгоритм отправки отчета МФД*/}
			{/*	</Link>*/}
			{/*	<Link to="#" download className="btn btn-block btn-primary mt-2">*/}
			{/*		Алгоритм отправки отчета ПСК*/}
			{/*	</Link>*/}
			{/*</div>*/}
		</div>
	)
})

export default Menu
