import React, { useEffect, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import { apiGet, apiPost } from "../../../api"
import { useFetch } from "../../../hooks/useFetch"
import { getUnReadMessages } from "../../../redux/actions/user"
import { downloadBase64File } from "../../../utils/downloadBase64"
import { Dropzone, Input, Modal } from "../../components"
import "./index.scss"

const MessageContainer = () => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const [modalSend, setModalSend] = useState(false)
	const [errObj, setErrObj] = useState({})
	const [newFiles, setNewFiles] = useState([])
	const [newSubject, setNewSubject] = useState("")
	const [newText, setNewText] = useState("")
	const [msgLoad, setMsgLoad] = useState(false)
	const { response: getMessage } = useFetch({
		url: `/mail-message/view?id=${id}`,
	})

	const history = useHistory()

	const downloadFile = id => {
		apiGet({
			url: `/mail-message/file?id=${id}&withContent=0`,
			timeout: 1000000,
		}).then(res => {
			if (res?.error) {
				console.error("Скачивание файла")
				return
			}
			apiGet({
				url: `/mail-message/file?id=${id}`,
				timeout: 1000000,
				fileWeight: res?.data?.sizeInBytes,
			}).then(res => {
				if (res?.error) {
					console.error("Скачивание файла")
					return
				}
				downloadBase64File(res.data.asBase64, res.data.name)
			})
		})
	}

	const downloadAllFiles = id => {
		apiGet({
			url: `/mail-message/files-as-archive?id=${id}`,
			fileWeight: getMessage?.[0]?.archiveSize,
		}).then(res => {
			if (res?.error) {
				console.error("Скачивание файла")
				return
			}
			downloadBase64File(res.data.content, res.data.name)
		})
	}

	const funcRecUpdate = children => {
		return (
			<div className=" ml-2 mt-2 left-border">
				<div
					style={{ whiteSpace: "pre-line" }}
					className=" col-auto"
					dangerouslySetInnerHTML={{ __html: children.body }}
				></div>
				{children.parentMessageStd && funcRecUpdate(children.parentMessageStd)}
			</div>
		)
	}

	const onNewFileRemove = fileName => {
		setNewFiles(newFiles.filter(f => f.name !== fileName))
	}

	const onNewFileUpload = filesData => {
		setNewFiles([...newFiles, ...filesData])
	}

	const createTicket = () => {
		setMsgLoad(true)
		let formData = new FormData()
		formData.append("title", newSubject)
		formData.append("body", newText)
		newFiles.forEach(file => {
			formData.append("files[]", file)
		})

		apiPost({
			url: `/mail-message/create?answerTo=${
				getMessage[getMessage.length - 1].id
			}`,
			postData: formData,
			errorCallback: setErrObj,
		})
			.then(res => {
				setMsgLoad(false)
				if (res.status === 406) {
					setErrObj(res.data)
				}
				if (res.error) {
					console.error("Создание обращения")
					return
				}
				setErrObj({})
				setNewSubject("")
				setNewText("")
				setModalSend(false)
				setNewFiles([])
				history.goBack()
			})
			.finally(() => setMsgLoad(false))
	}

	const closeNewModal = () => setModalSend(false)
	const openNewModal = () => setModalSend(true)

	const getUnreadableMsg = () => {
		apiGet({ url: `/mail-message/not-viewed-count` }).then(res => {
			if (res?.error) {
				console.error("Получение непрочитанных сообщений")
				return
			}
			dispatch(getUnReadMessages(res.data))
		})
	}

	useEffect(() => {
		getUnreadableMsg()
		setNewSubject(getMessage?.[getMessage.length - 1].title)
	}, [getMessage])

	return (
		<>
			<div className="message">
				<div className="page-title">Сообщениe</div>
				<div className="row">
					<div className="col-auto">
						<button className="btn btn-primary" onClick={openNewModal}>
							Ответить
						</button>
					</div>
				</div>
				<div className="card mt-4">
					{getMessage?.map((i, index) => (
						<>
							{" "}
							<div key={index} className="card-header d-flex flex-column">
								<div>Тема: {getMessage?.[getMessage.length - 1].title} </div>
								<div>
									{" "}
									От:{" "}
									{i.from === 2
										? "sromfoalliance"
										: `${
												JSON.parse(localStorage.getItem("organization")).name
												// eslint-disable-next-line no-mixed-spaces-and-tabs
										  }`}
								</div>
							</div>
							<div className="card-content">
								{" "}
								<div className="row  mt-4 mail" key={index}>
									<div className="col-10">
										<div
											style={{ whiteSpace: "pre-line" }}
											className=""
											dangerouslySetInnerHTML={{ __html: i.body }}
										/>
										<div className="files">
											{i.files.map(f => (
												<span
													className="file-mail"
													key={f.name}
													onClick={() => downloadFile(f.id)}
												>
													{f.name}
												</span>
											))}
											{i.isHasFile && (
												<span
													className="file-mail"
													onClick={() => downloadAllFiles(i.id)}
												>
													Скачать все файлы
												</span>
											)}
										</div>
									</div>

									<div className="col-2">
										<span className="small-date">{i.createdAt}</span>
									</div>
									<div className="childrens">
										{i.parentMessageStd && funcRecUpdate(i.parentMessageStd)}
									</div>
								</div>
							</div>
						</>
					))}
				</div>
			</div>
			<Modal
				show={modalSend}
				modalStyles={{ maxWidth: 600 }}
				onClose={closeNewModal}
			>
				<Input
					label="Тема"
					value={newSubject}
					onChange={e => setNewSubject(e.target.value)}
					error={errObj.title}
				/>{" "}
				<ReactQuill value={newText} onChange={setNewText} />
				{errObj.body && <span className="_error mt-1">{errObj.body}</span>}
				<div className="form-field mt-3">
					<Dropzone
						maxSize={100000000}
						files={newFiles}
						onFileRemove={onNewFileRemove}
						onFileUpload={onNewFileUpload}
						styles={{ height: 80 }}
					/>

					{errObj.files && (
						<span style={{ color: "#f46a6a", fontSize: "12px" }}>
							{errObj.files}
						</span>
					)}
				</div>
				<button
					className={`btn btn-primary ${msgLoad ? "btn-lock" : ""}`}
					onClick={() => (msgLoad ? null : createTicket())}
				>
					Отправить
				</button>
			</Modal>
		</>
	)
}

export default MessageContainer
