import PropTypes from "prop-types"
import React, { memo } from "react"
import "../input.scss"

const Input = ({ label = null, fieldClasses = "", error = null, ...props }) => {
	let inputClasses = "form-input"
	
	if (error) {
		inputClasses += " _error"
	}
	
	return (
		<label className={`form-field ${fieldClasses}`}>
			{label && <div className="form-label">{label}</div>}
			
			<input className={inputClasses} {...props} />
			
			{error && <div className="form-error">{error}</div>}
		</label>
	)
}
Input.propTypes = {
	fieldClasses: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.any,
}

export default memo(Input)
