import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { apiPost } from "../../../../api"
import { appLogin } from "../../../../redux/actions/app"
import {
	updateOrganization,
	updateProfile,
} from "../../../../redux/actions/user"
// import logo from "../../../../static/img/icon/logo_edinstvo.svg"
import { Input } from "../../../components"
import "./index.scss"

const LoginPage = () => {
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [errObj, setErrObj] = useState({})
	const [formData, setFormData] = useState({
		username: "",
		password: "",
	})

	const changeInputValue = e => {
		const { name, value } = e.target

		const data = { ...formData }
		data[name] = value

		setFormData(data)
	}

	const onLogin = e => {
		e.preventDefault()

		setLoading(true)

		if (!loading) {
			apiPost({ url: "/auth/login", postData: formData }).then(res => {
				setLoading(false)

				if (res.status === 406) {
					setErrObj(res.data)
				} else if (res.error) {
					console.error("Авторизации")
					return
				}

				dispatch(updateOrganization(res.data.organization))
				dispatch(updateProfile(res.data.profile))
				dispatch(appLogin(res.data.access_token))
			})
		}
	}

	return (
		<div className="page-auth">
			<div className="container">
				<div className="my-5 row jc-center">
					<div className="col-md-9 col-lg-7 col-xl-4">
						<form className="form" onSubmit={onLogin}>
							{/*<img className="mb-5" src={logo} />*/}
							<Input
								name="username"
								value={formData.username}
								onChange={changeInputValue}
								label="Username"
								error={errObj.username}
							/>
							<Input
								name="password"
								value={formData.password}
								onChange={changeInputValue}
								type="password"
								label="Пароль"
								error={errObj.password}
							/>

							<button type="submit" className="btn btn-primary btn-block">
								Войти
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginPage
