/* eslint no-use-before-define: 0 */
import PropTypes from "prop-types"
import React, { useState } from "react"
import { confirmAlert } from "react-confirm-alert"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useDispatch } from "react-redux"
import { withRouter } from "react-router"
import { apiGet, apiPost } from "../../../api"
import { useFetch } from "../../../hooks/useFetch"
import useUrl from "../../../hooks/useUrl"
import { getUnReadMessages } from "../../../redux/actions/user"
import { Dropzone, Input, Modal, Pagination, Spinner } from "../../components"
import PaperClip from "../../icons/PaperClip"

const MessagesContainer = ({ history }) => {
	const dispatch = useDispatch()
	const { page, from } = useUrl()
	const [messageLoad, setMessageLoad] = useState(false)
	const [newModalShow, setNewModalShow] = useState(false)
	const [newFiles, setNewFiles] = useState([])
	const [newSubject, setNewSubject] = useState("")
	const [newText, setNewText] = useState("")
	const [errObj, setErrObj] = useState({})
	let url = `/mail-message?page=${page}&limit=10`
	if (from) {
		url += `&from=${from}`
	}

	const {
		response: subjectResponse,
		loading: subjectLoading,
		fetchData: getSubjects,
	} = useFetch({
		url: url,
	})

	const goToHistory = id => {
		history.push(`/mail/message/${id}`)
	}

	const closeNewModal = () => setNewModalShow(false)
	const openNewModal = () => setNewModalShow(true)

	/**
	 * Создание обращения
	 */
	const createTicket = () => {
		setMessageLoad(true)
		let formData = new FormData()
		formData.append("title", newSubject)
		formData.append("body", newText)
		newFiles.forEach(file => {
			formData.append("files[]", file)
		})

		apiPost({
			url: "/mail-message/create?",
			postData: formData,
			errorCallback: setErrObj,
		})
			.then(res => {
				setMessageLoad(false)
				if (res.status === 406) {
					setErrObj(res.data)
				}
				if (res.error) {
					console.error("Создание обращения")
					return
				}
				setErrObj({})
				setNewSubject("")
				setNewText("")
				setNewModalShow(false)
				getSubjects()
				setNewFiles([])
			})
			.finally(() => setMessageLoad(false))
	}

	/**
	 * Удаление файла при создании обращения
	 * @param {string} fileName - название файла
	 */
	const onNewFileRemove = fileName => {
		setNewFiles(newFiles.filter(f => f.name !== fileName))
	}

	/**
	 * Добавление файла при создании обращения
	 * @param {file} filesData - файл
	 */
	const onNewFileUpload = filesData => {
		setNewFiles([...newFiles, ...filesData])
	}

	const alertUnreadMessage = (id, e) => {
		e.preventDefault()
		confirmAlert({
			title: "",
			message: "Вы точно хотите сделать сообщение не прочитаным?",
			buttons: [
				{
					label: "Да",
					onClick: () => toggleViewMessage(id),
				},
				{
					label: "Нет",
				},
			],
		})
	}

	const alertBlockUndreadMessage = () => {
		// e.preventDefault()
		confirmAlert({
			title: "",
			message:
				"Вы не можете сделать не прочитаным сообщение отправленное вами.",
			buttons: [
				{
					label: "Ok",
					onClick: e => e,
				},
			],
		})
	}

	const getUnreadableMsg = () => {
		apiGet({ url: `/mail-message/not-viewed-count` }).then(res => {
			if (res?.error) {
				console.error("Получение непрочитанных сообщений")
				return
			}
			dispatch(getUnReadMessages(res.data))
		})
	}

	const toggleViewMessage = id => {
		apiGet({ url: `/mail-message/set-as-not-viewed?id=${id}` }).then(res => {
			if (res.error) {
				console.error("Сообщение сделать прочитанным.")
				return
			}
			getUnreadableMsg()
			getSubjects()
		})
	}

	return (
		<div className="inbox">
			<div className="page-title">{from == 2 ? "Входящие" : "Исходящие"}</div>
			<button className="btn btn-danger" onClick={() => openNewModal()}>
				Создать обращение
			</button>

			<div className="messages mt-4">
				{subjectLoading ? (
					<div className="d-flex jc-center">
						<Spinner />
					</div>
				) : (
					subjectResponse?.models?.map(item => {
						let className = "d-flex ai-center message cursor-pointer"
						const {
							id,
							title,
							body,
							isViewed,
							isHasFile,
							createdAt,
							from,
						} = item

						if (!isViewed) className += " _new"

						return (
							<div
								key={id}
								className={className}
								onClick={e =>
									e.target.className !== "badge badge-green" && goToHistory(id)
								}
							>
								<span className="from pl-3">
									{from === 2
										? "от: sromfoalliance"
										: `от: ${
												JSON.parse(localStorage.getItem("organization")).name
												// eslint-disable-next-line no-mixed-spaces-and-tabs
										  }`}
								</span>

								<span className="from pl-3">Тема: {title}</span>
								<span
									className="text px-3  ai-center"
									style={{ fontSize: "14px" }}
									dangerouslySetInnerHTML={{ __html: body }}
								/>

								<span>
									{isHasFile && <PaperClip className="mr-2 paperClip" />}
								</span>
								<span className="date pr-3">{createdAt}</span>
								<div className=" mr-3">
									{isViewed ? (
										<span
											onClick={e =>
												from == "2"
													? alertUnreadMessage(item.id, e)
													: alertBlockUndreadMessage()
											}
											className="badge badge-green"
										>
											Прочитано
										</span>
									) : (
										<span className="badge badge-danger w-100">
											Не прочитано
										</span>
									)}
								</div>
							</div>
						)
					})
				)}
			</div>

			<div className="mt-3 ta-center">
				<Pagination lastPage={subjectResponse?.lastPage} page={page} />
			</div>

			<Modal
				show={newModalShow}
				modalStyles={{ maxWidth: 600 }}
				onClose={closeNewModal}
			>
				<Input
					label="Тема"
					value={newSubject}
					onChange={e => setNewSubject(e.target.value)}
					error={errObj.title}
				/>
				<ReactQuill value={newText} onChange={setNewText} />

				<div className="form-field mt-3">
					<Dropzone
						maxSize={100000000}
						files={newFiles}
						onFileRemove={onNewFileRemove}
						onFileUpload={onNewFileUpload}
						styles={{ height: 80 }}
					/>
					{errObj.files && (
						<span style={{ color: "#f46a6a", fontSize: "12px" }}>
							{errObj.files}
						</span>
					)}
				</div>

				<button
					className={`btn btn-primary ${messageLoad ? "btn-lock" : ""}`}
					onClick={() => (messageLoad ? null : createTicket())}
				>
					Создать обращение
				</button>
			</Modal>
		</div>
	)
}

MessagesContainer.propTypes = {
	history: PropTypes.any,
}

export default withRouter(MessagesContainer)
