import React from "react"
import { ReportViewContainer } from "../../../../containers"

const ReportViewPage = () => {
	return (
		<div className="page-report-view">
			<ReportViewContainer />
		</div>
	)
}

export default ReportViewPage
