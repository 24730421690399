import PropTypes from "prop-types"
import React from "react"

function SvgComponent ({ size = 18, color = "#fff", ...props }) {
	return (
		<svg
			height={size}
			width={size}
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 374.116 374.116"
			{...props}
		>
			<path
				d="M344.058 207.506c-16.568 0-30 13.432-30 30v76.609h-254v-76.609c0-16.568-13.432-30-30-30-16.568 0-30 13.432-30 30v106.609c0 16.568 13.432 30 30 30h314c16.568 0 30-13.432 30-30V237.506c0-16.568-13.432-30-30-30z" />
			<path
				d="M123.57 135.915l33.488-33.488v111.775c0 16.568 13.432 30 30 30 16.568 0 30-13.432 30-30V102.426l33.488 33.488c5.857 5.858 13.535 8.787 21.213 8.787 7.678 0 15.355-2.929 21.213-8.787 11.716-11.716 11.716-30.71 0-42.426l-84.701-84.7c-11.715-11.717-30.711-11.717-42.426 0L81.144 93.489c-11.716 11.716-11.716 30.71 0 42.426 11.715 11.716 30.711 11.716 42.426 0z" />
		</svg>
	)
}

SvgComponent.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default SvgComponent
