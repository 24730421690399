import PropTypes from "prop-types"
import React, { memo } from "react"
import "../input.scss"

const Textarea = ({
	label = null,
	fieldClasses = "",
	error = null,
	...props
}) => {
	let inputClasses = "form-input"

	if (error) {
		inputClasses += "_error"
	}

	return (
		<label className={`form-field ${fieldClasses}`}>
			{label && <div className="form-label">{label}</div>}

			<textarea className={inputClasses} {...props} />

			{error && <div className="form-error">{error}</div>}
		</label>
	)
}
Textarea.propTypes = {
	fieldClasses: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.any,
}

export default memo(Textarea)
