/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { useSelector } from "react-redux"
import LoadPrecent from "../../components/LoadPrecent"

const Footer = () => {
	const state = useSelector(state => state)

	return (
		<footer id="page-footer">
			<div className="footer-content ta-center f-300 d-flex jc-between">
				<span className="d-block">2021 © СРО Альянс</span>
				<div className="made">
					Сделано в{" "}
					<a
						rel="noreferrer"
						href="https://wocom.biz/"
						target="_blank"
						className="f-700"
					>
						WOCOM
					</a>
				</div>
			</div>
			{state.user?.uploadingProgress?.loading ? <LoadPrecent /> : ""}
		</footer>
	)
}

export default Footer
