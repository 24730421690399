import axios from "axios"
import { uploadingProgress } from "../redux/actions/user"
import { store } from "../redux/store"
import { errorHandler } from "../utils/errorHandler"

export const Api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 100000,
})

export const apiPost = async ({ url, postData = null, headerList = {} }) => {
	const { authToken } = store.getState().app
	const headers = { ...headerList, Authorization: `Bearer ${authToken}` }

	try {
		const res = await Api.post(url, postData, {
			headers,
			onUploadProgress: progressEvent => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				)
				if (percentCompleted % 5 === 0) {
					store.dispatch(
						uploadingProgress({ loading: true, progress: percentCompleted })
					)
				}
			},
		})
		store.dispatch(uploadingProgress({ loading: false, progress: 0 }))
		return res.data
	} catch (e) {
		errorHandler({
			url,
			errorText: e.message,
		})
	}
}

export const apiGet = async ({
	url,
	headerList = {},
	timeout = 100000,
	fileWeight = null,
}) => {
	const { authToken } = store.getState().app
	const headers = { ...headerList, Authorization: `Bearer ${authToken}` }

	try {
		const res = await axios
			.create({
				baseURL: process.env.REACT_APP_API_URL,
				timeout: timeout,
			})
			.get(url, {
				headers,
				onDownloadProgress: e => {
					const percentCompleted = Math.round((e.loaded * 100) / fileWeight)

					if (percentCompleted % 5 === 0 && percentCompleted < 100) {
						store.dispatch(
							uploadingProgress({ loading: true, progress: percentCompleted })
						)
					}
					if (percentCompleted === 99) {
						store.dispatch(
							uploadingProgress({ loading: true, progress: percentCompleted })
						)
					}
				},
			})
		store.dispatch(uploadingProgress({ loading: false, progress: 0 }))
		return res.data
	} catch (e) {
		errorHandler({
			url,
			errorText: e.message,
		})
	}
}
