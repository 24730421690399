const getUrlParams = () => {
	const params = {}
	const url = window.location.search.substring(1)
	if (url.length > 0) {
		url.split("&").forEach(i => {
			const tmp = i.split("=")

			params[tmp[0]] = tmp[1]
		})
	}

	return params
}

export default getUrlParams
