import React from "react"

export const Notification = () => {
	return (
		<div className='report-notification'>
			Уважаемые коллеги! Убедительная просьба, при проведении платежей на расчетный счет Союза “МИКРОФИНАНСОВЫЙ
			АЛЬЯНС” указывать назначение платежа, в случае, если назначения платежа не будет указано, деньги будут
			возвращаться на р/с отправителя.
		</div>
	)
}
