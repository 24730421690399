import React from "react"
import { useFetch } from "../../../hooks/useFetch"
import { Input, Spinner } from "../../components"

const ProfileContainer = () => {
	const { response, loading } = useFetch({ url: "/user/profile" })

	if (loading) {
		return (
			<div className="d-flex jc-center">
				<Spinner />
			</div>
		)
	}

	return (
		<div className="card">
			<div className="card-header">Профиль</div>
			<div className="card-content">
				<div className="row">
					<div className="col-12 col-md-4">
						<Input
							disabled
							label="Фамилия"
							defaultValue={response?.lastName || ""}
						/>
					</div>
					<div className="col-12 col-md-4">
						<Input disabled label="Имя" defaultValue={response?.name || ""} />
					</div>
					<div className="col-12 col-md-4">
						<Input
							disabled
							label="Отчество"
							defaultValue={response?.patronymic || ""}
						/>
					</div>
					<div className="col-12 col-md-4">
						<Input
							disabled
							label="E-Mail"
							defaultValue={response?.email || ""}
						/>
					</div>
					<div className="col-12 col-md-4">
						<Input
							disabled
							label="Статус"
							defaultValue={response?.statusText || ""}
						/>
					</div>
					<div className="col-12 col-md-4">
						<Input
							disabled
							label="Телефон"
							defaultValue={response?.phone || ""}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfileContainer
