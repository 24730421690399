import PropTypes from "prop-types"
import React from "react"
import spinnerWhiteImg from "./spinner-white.svg"

const Spinner = ({ size = 120, styles = {}, ...props }) => (
	<img
		alt="spinner animation"
		src={spinnerWhiteImg}
		style={{ width: size, height: size, ...styles }}
		{...props}
	/>
)
Spinner.propTypes = {
	size: PropTypes.number,
	styles: PropTypes.object,
}

export default Spinner
