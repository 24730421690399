import PropTypes from "prop-types"
import React from "react"

function SvgComponent ({ size = 18, color = "#fff", ...props }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 374.117 374.117"
			height={size}
			width={size}
			fill={color}
			{...props}
		>
			<path
				d="M344.059 207.508c-16.568 0-30 13.432-30 30v76.609h-254v-76.609c0-16.568-13.432-30-30-30s-30 13.432-30 30v106.609c0 16.568 13.432 30 30 30h314c16.568 0 30-13.432 30-30V237.508c0-16.569-13.432-30-30-30z" />
			<path
				d="M250.547 108.287l-33.488 33.488V30c0-16.568-13.432-30-30-30s-30 13.432-30 30v111.775l-33.488-33.488c-5.857-5.857-13.535-8.787-21.213-8.787-7.678 0-15.355 2.93-21.213 8.787-11.715 11.717-11.715 30.711 0 42.426l84.701 84.701c11.715 11.717 30.711 11.717 42.426 0l84.701-84.701c11.717-11.715 11.717-30.709 0-42.426-11.715-11.717-30.711-11.717-42.426 0z" />
		</svg>
	)
}

SvgComponent.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default SvgComponent
