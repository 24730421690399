import React from "react"
import { useFetch } from "../../../hooks/useFetch"
import { Input, Spinner } from "../../components"

const CompanyInformation = () => {
	const { response, loading } = useFetch({ url: "/organization" })
	console.log(response)

	if (loading) {
		return (
			<div className="d-flex jc-center">
				<Spinner />
			</div>
		)
	}

	return (
		<div className="card">
			<div className="card-header">Компания</div>
			<div className="card-content">
				<div className="row d-flex ai-end">
					{/* <div className="col-12 col-md-4">
						<Input
							disabled
							label="Название"
							defaultValue={response?.name || ""}
						/>
					</div> */}
					<div className="col-12">
						<Input disabled label="Полное наименование организации" defaultValue={response?.name_full || ""} />
					</div>
					<div className="col-12">
						<Input disabled label="Адрес места нахождения микрокредитной компании" defaultValue={response?.address || ""} />
					</div>
					<div className="col-12 col-md-4">
						<Input disabled label="ОГРН" defaultValue={response?.ogrn || ""} />
					</div>
					<div className="col-12 col-md-4">
						<Input disabled label="ИНН" defaultValue={response?.inn || ""} />
					</div>
					<div className="col-12 col-md-4">
						<Input disabled label="Регистрационный номер в государственном реестре МФО" defaultValue={response?.reg_number || ""} />
					</div>
					<div className="col-12 col-md-4">
						<Input disabled label="ОКТМО" defaultValue={response?.oktmo || ""} />
					</div>
					<div className="col-12 col-md-4">
						<Input disabled label="ОКАТО" defaultValue={response?.okato || ""} />
					</div>
					<div className="col-12 col-md-4">
						<Input disabled label="ОКОПФ" defaultValue={response?.okopf || ""} />
					</div>

				</div>
			</div>
		</div>
	)
}

export default CompanyInformation
