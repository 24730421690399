/* eslint-disable react/no-unescaped-entities */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { apiGet, apiPost } from "../../../api"
// import defaultAvatarImg from "../../../static/img/default_avatar.jpg"
// import logoLogo from "../../../static/img/icon/logo_edinstvo.svg"
import burgerMenuImg from "../../../static/img/icon/menu.svg"
import "./index.scss"

const Header = ({ onOpenMobMenu }) => {
	const state = useSelector(state => state)
	const [checkTheme, setCheckTheme] = useState(false)

	const getTheme = () => {
		apiGet({ url: "/user/profile" }).then(res => {
			setCheckTheme(res.data.theme)
		})
	}

	const changeTheme = () => {
		apiPost({
			url: "/user/set-theme",
			postData: {
				theme: checkTheme == 2 ? 1 : 2,
			},
		}).then(() => {
			setCheckTheme(checkTheme == 2 ? 1 : 2)
		})
	}

	useEffect(() => {
		localStorage.setItem("theme", `${checkTheme == 1 ? "_dark" : "_light"}`)
		const htmlCls = document.getElementById("html").classList
		const theme = localStorage.getItem("theme")
		if (theme === "_dark") {
			htmlCls.remove("_light")
			htmlCls.add("_dark")
		}
		if (theme === "_light") {
			htmlCls.remove("_dark")
			htmlCls.add("_light")
		}
	}, [checkTheme])

	useEffect(() => {
		getTheme()
	}, [])

	return (
		<div className="header d-flex ai-center">
			<div className="l-side d-flex ai-center">
				<button
					className="d-block d-xl-none mob-menu mr-3"
					onClick={() => onOpenMobMenu()}
				>
					<img src={burgerMenuImg} alt="" />
				</button>

				{/*<Link to="/" className="logo d-flex">*/}
				{/*	<img src={logoLogo} alt="logo" />*/}
				{/*</Link>*/}
			</div>

			<div className="r-side d-flex ai-center flex-fill jc-end">
				<div className="check-theme ">
					<label>
						<input
							type="checkbox"
							name="checkboxName"
							className="checkbox-theme"
							checked={checkTheme}
							onChange={() => changeTheme()}
						/>
						<div className={`switch ${checkTheme == true && "active"}`}>
							<div className="circle"></div>
						</div>
					</label>
				</div>
				<Link
					to="/profile"
					className="user d-flex ai-center f-500 c-text-fade h6 ml-3"
				>
					{/*<img*/}
					{/*	src={state.user.profile.avatar || defaultAvatarImg}*/}
					{/*	alt=""*/}
					{/*	className="avatar avatar-circle"*/}
					{/*/>*/}

					<span className="d-none d-md-block ml-2">
						{state.user.profile.name}
					</span>
				</Link>
			</div>
		</div>
	)
}
Header.propTypes = {
	onOpenMobMenu: PropTypes.func.isRequired,
}

export default Header
