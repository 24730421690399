import PropTypes from "prop-types"
import React from "react"

function SvgComponent ({ size = 18, color = "#fff", ...props }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 230.17 230.17"
			width={size}
			height={size}
			fill={color}
			{...props}
		>
			<path
				d="M230 49.585c0-.263.181-.519.169-.779l-70.24 67.68 70.156 65.518c.041-.468-.085-.94-.085-1.418V49.585zM149.207 126.901l-28.674 27.588a7.48 7.48 0 01-5.2 2.096 7.478 7.478 0 01-5.113-2.013l-28.596-26.647-70.614 68.064c1.717.617 3.56 1.096 5.49 1.096h197.667c2.866 0 5.554-.873 7.891-2.175l-72.851-68.009z" />
			<path
				d="M115.251 138.757L222.447 35.496c-2.427-1.443-5.252-2.411-8.28-2.411H16.5c-3.943 0-7.556 1.531-10.37 3.866l109.121 101.806zM0 52.1v128.484c0 1.475.339 2.897.707 4.256l69.738-67.156L0 52.1z" />
		</svg>
	)
}

SvgComponent.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default SvgComponent
