import PropTypes from "prop-types"
import * as React from "react"

const ArrowDown = ({ size = 15, color = "#fff", ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 240.811 240.811"
			width={size}
			height={size}
			fill={color}
			{...props}
		>
			<path d="M220.088 57.667l-99.671 99.695-99.671-99.707a12.147 12.147 0 00-17.191 0c-4.74 4.752-4.74 12.451 0 17.203l108.261 108.297c4.74 4.752 12.439 4.752 17.179 0L237.256 74.859c4.74-4.752 4.74-12.463 0-17.215-4.728-4.729-12.428-4.729-17.168.023z" />
		</svg>
	)
}

ArrowDown.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default ArrowDown
