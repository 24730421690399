import PropTypes from "prop-types"
import React from "react"

function SvgComponent ({ size = 18, color = "#fff", ...props }) {
	return (
		<svg
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill={color}
			{...props}
		>
			<path
				d="M11 15c-1.654 0-3-1.346-3-3s1.346-3 3-3h5V2.75A2.752 2.752 0 0013.25 0H2.75A2.752 2.752 0 000 2.75v18.5A2.752 2.752 0 002.75 24h10.5A2.752 2.752 0 0016 21.25V15z" />
			<path
				d="M18.617 16.924A1.002 1.002 0 0118 16v-3h-7a1 1 0 010-2h7V8a1.002 1.002 0 011.707-.707l4 4a.999.999 0 010 1.414l-4 4a1 1 0 01-1.09.217z" />
		</svg>
	)
}

SvgComponent.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default SvgComponent
