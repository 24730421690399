import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "./components/components"
import AuthFlow from "./components/pages/Auth"
import MainFlow from "./components/pages/Main"
import { appLogin } from "./redux/actions/app"
import { updateOrganization, updateProfile } from "./redux/actions/user"
import { checkUserAuth } from "./utils/checkAuth"

const App = () => {
	const state = useSelector(state => state)
	const dispatch = useDispatch()

	const [isLoading, setLoading] = useState(true)

	useEffect(() => {
		checkUserAuth().then(res => {
			if (res) {
				dispatch(updateProfile(res.userData))
				dispatch(updateOrganization(res.organization))
				dispatch(appLogin(res.authToken))
			}

			setLoading(false)
		})
	}, [])

	if (isLoading) {
		return (
			<div className="d-flex jc-center ai-center" style={{ height: "100%" }}>
				<Spinner />
			</div>
		)
	}

	return state.app.authToken ? <MainFlow /> : <AuthFlow />
}

export default App
