import React from "react"
import { useSelector } from "react-redux"
import Spinner from "../Spinner"
import "./index.scss"

const LoadPrecent = () => {
	const state = useSelector(state => state)
	return (
		<div className="upload-doc d-flex flex-column">
			<div className="">
				<Spinner />
			</div>
			{state.user.uploadingProgress?.progress &&
				`	Загрузка..${state.user.uploadingProgress?.progress}%`}
		</div>
	)
}

export default LoadPrecent
