import React, { lazy, Suspense, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch, useLocation } from "react-router-dom"
import { apiGet } from "../../../api"
import { getUnReadMessages } from "../../../redux/actions/user"
import Spinner from "../../components/Spinner"
import Footer from "../../layouts/Footer"
import Header from "../../layouts/Header"
import Menu from "../../layouts/Menu"
// import MessagePage from "./Message/index"
import ReportViewPage from "./Reports/View"
import { Notification } from "../../components/Notification/Notification"

const HomePage = lazy(() => import("./Home"))
const CalendarPage = lazy(() => import("./Calendar"))
const MailPage = lazy(() => import("./Mail"))
const MessagePage = lazy(() => import("./Message"))
const ProfilePage = lazy(() => import("./Profile"))
const ReportListPage = lazy(() => import("./Reports/List"))
const CalenarAppPage = lazy(() => import("./CalenadApp"))
const TemplatesListPage = lazy(() => import("./Reports/Templates"))

const MainFlow = () => {
	const state = useSelector(state => state)
	const location = useLocation()
	const dispatch = useDispatch()

	const getUnreadableMsg = () => {
		apiGet({ url: `/mail-message/not-viewed-count` }).then(res => {
			if (res?.error) {
				console.error("Получение непрочитанных сообщений")
				return
			}
			dispatch(getUnReadMessages(res.data))
		})
	}

	useEffect(() => {
		getUnreadableMsg()
	}, [location.pathname])

	const mobMenuRef = useRef()

	const openMobMenu = () => {
		mobMenuRef.current.classList.toggle("_visible")
	}

	if (!state.user.organization) {
		return (
			<div className="d-flex jc-center ai-center flex-fill">
				<p className="c-text h5 px-3">
					У Вас нет организации. Обратитесь к администратору.
				</p>
			</div>
		)
	}

	return (
		<>
			<Header onOpenMobMenu={openMobMenu} />

			<div className="flex-fill content d-flex">
				<Menu ref={mobMenuRef} />

				<div className="content-block">
					<Notification />

					<Suspense fallback={<Spinner />}>
						<Switch>
							<Route path="/" exact component={CalendarPage} />
							<Route path="/calendar" component={CalendarPage} />
							<Route path="/events" exact component={HomePage} />
							<Route path="/mail" exact component={MailPage} />
							<Route path="/mail/message/:id" component={MessagePage} />
							<Route path="/profile" component={ProfilePage} />
							<Route path="/report/list/:templateId" component={ReportListPage} />
							<Route path="/report/view/:reportId" component={ReportViewPage} />
							<Route path="/calendar-app" component={CalenarAppPage} />
							<Route path="/report/templates" component={TemplatesListPage} />
						</Switch>
					</Suspense>
				</div>
			</div>

			<Footer />
		</>
	)
}

export default MainFlow
