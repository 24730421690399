export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return "0 Байт"
	
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ["Байт", "Кб", "Мб", "Гб", "Тб"]
	
	const i = Math.floor(Math.log(bytes) / Math.log(k))
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}
