import React, { useEffect, useState } from "react"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css alert
import { useHistory, useParams } from "react-router-dom"
import { apiGet, apiPost } from "../../../../api"
import { REPORT_STATUS_LIST } from "../../../../global/data"
import { useFetch } from "../../../../hooks/useFetch"
import useUrl from "../../../../hooks/useUrl"
import { downloadBase64File } from "../../../../utils/downloadBase64"
import { Dropzone, Modal, Pagination, Spinner } from "../../../components"
import Badge from "../../../components/Badge"
import { IconDownload, IconUpload } from "../../../icons"

const ReportListContainer = () => {
	const history = useHistory()
	const { templateId } = useParams()
	const { page } = useUrl()

	const [reportId, setReportId] = useState(null)
	const [files, setFiles] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [isUpload, setIsUpload] = useState(false)
	// const [checkOnChecking, setCheckOnChecking] = useState(false)
	// const [incomingNum, setIncomingNum] = useState("")
	const [errObj, setErrObj] = useState({})
	// const [yearReportUpload, setYearReportUpload] = useState(
	// 	new Date().getFullYear()
	// )

	const { response, loading, fetchData: refreshReports } = useFetch({
		url: `/report?template_id=${templateId}&page=${page || 1}&limit=10`,
	})

	const reportUploaded = text => {
		confirmAlert({
			message: text,
			buttons: [
				{
					label: "Ок",
					onClick: () => {
					}
				}
			]
		})
	}

	const openModal = id => {
		setReportId(id)
		setShowModal(true)
	}
	const closeModal = () => setShowModal(false)

	// const emptyIncomingNum = () => {
	// 	confirmAlert({
	// 		message:
	// 			"Пожалуйста введите входящий номер или установите отчет как отчет на проверку",
	// 		buttons: [
	// 			{
	// 				label: "Ок",
	// 				onClick: () => {},
	// 			},
	// 		],
	// 	})
	// }

	// const incNumInvalidAlert = () => {
	// 	confirmAlert({
	// 		message:
	// 			"Если вы отправляете отчёт на проверку укажите это с помощью галочки, поле с номером оставить пустым",
	// 		buttons: [
	// 			{
	// 				label: "Ок",
	// 				onClick: () => {},
	// 			},
	// 		],
	// 	})
	// }

	// const wrangNum = text => {
	// 	confirmAlert({
	// 		message: (
	// 			<div
	// 				style={{ whiteSpace: "pre-line" }}
	// 				dangerouslySetInnerHTML={{ __html: text }}
	// 			></div>
	// 		),
	// 		buttons: [
	// 			{
	// 				label: "Ок",
	// 				onClick: () => {},
	// 			},
	// 		],
	// 	})
	// }

	/**
   * Загрузка файла отета
   */
	const uploadReport = () => {
		// if (checkOnChecking == false && incomingNum == "") {
		// 	closeModal()
		// 	emptyIncomingNum()
		// 	return
		// }

		// if (incomingNum.length === 8) {
		// 	let newIncNum = incomingNum.split(/-|_/)
		// 	let validIncNum = newIncNum[newIncNum.length - 1].replace(/0/g, "")

		// 	if (validIncNum == "") {
		// 		closeModal()
		// 		incNumInvalidAlert()
		// 		return
		// 	}

		// 	let checkNum = newIncNum[newIncNum.length - 1] + ""
		// 	let newCheckNum = ""
		// 	for (let i = 0; i < checkNum.length; i++) {
		// 		if (checkNum[i] != 0) {
		// 			newCheckNum = checkNum.substr(i) + ""
		// 			break
		// 		} else newCheckNum = checkNum.substr(i + 1) + ""
		// 	}

		// 	if (newCheckNum < 1000000) {
		// 		closeModal()
		// 		wrangNum(
		// 			"Входящий номер некорректный! </br> Введите входящий номер присвоенный ЦБ"
		// 		)
		// 		return
		// 	}
		// } else {
		// 	closeModal()
		// 	wrangNum("Количество чисел должно быть 8")
		// }

		// if (checkOnChecking == true) {
		// 	setIncomingNum("")
		// }
		if (!isUpload) {
			setIsUpload(true)
			const postData = new FormData()
			// !checkOnChecking &&
			// 	postData.append(
			// 		"incomingNumber",
			// 		`ТР-${yearReportUpload}-${incomingNum}`
			// 	)
			postData.append("file", files[0])

			apiPost({ url: `/report/upload?id=${reportId}`, postData })
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}

					reportUploaded("Документ успешно загружен!")
					setErrObj({})
					setFiles([])
					// setIncomingNum("")
					refreshReports()
					closeModal()
				})
				.finally(() => setIsUpload(false))
		}
	}


	const downloadDocument = id => {
		apiGet({ url: `/report/download?id=${id}` }).then(res => {
			if (res.error) {
				console.error("Скачивание документа")
				return
			}

			downloadBase64File(res.data.asBase64, res.data.name)
		})
	}

	// const funcIncomingNum = e => {
	// 	setErrObj({})
	// 	if (e.length >= 9) {
	// 		setErrObj({ incNum: "Максимальное количество  цифр - 8" })
	// 		return
	// 	}
	// 	setIncomingNum(e)
	// }

	const onFileRemove = fileName => {
		setFiles(files.filter(f => f.name !== fileName))
	}


	const onFileUpload = filesData => {
		setFiles(filesData)
	}

	useEffect(() => {
		setErrObj({})
	}, [files])

	if (loading) {
		return (
			<div className="d-flex jc-center">
				<Spinner />
			</div>
		)
	}

	return (
		<div className="report card">
			<div className="card-header f-500">
				Протокол отправки отчетов
			</div>
			<div className="card-content">
				<div className="scroll-x">
					<table>
						<thead>
							<tr>
								<th>Название</th>
								<th>Период</th>
								<th>Дата отчетного периода</th>
								<th>Дата отправки</th>
								<th>Статус</th>

								<th></th>
							</tr>
						</thead>

						<tbody>
							{response.models.map(i => {
								const {
									id,
									template: { name },
									uploadTime,
									status,
									statusText,
									period: { startDate, endDate },
									isBlockUpload
								} = i

								const { type } = REPORT_STATUS_LIST[+status - 1]

								return (
									<tr
										key={id}
										className="reports go-table-link"
										style={{ cursor: "pointer" }}
										onClick={() => history.push(`/report/view/${id}`)}
									>
										<td style={{ maxWidth: 250 }}>{name}</td>
										<td>{i.period?.name}</td>
										<td>
											{startDate} - {endDate}
										</td>
										<td>{uploadTime}</td>

										<td>
											<Badge type={type}>{statusText}</Badge>
										</td>
										<td>
											<div className="d-flex row-sm jc-end">
												<div className="col-auto">
													{!isBlockUpload && (<button
														className="btn btn-primary btn"
														onClick={(e) => {
															e.stopPropagation()
															openModal(id)
														}}
														title="Загрузить"
													>
														<IconUpload size={20} color="#fff" />
													</button>)}
												</div>
												{status !== 1 && (
													<div className="col-auto">
														<button
															className="btn btn-primary btn"
															onClick={(e) => {
																e.stopPropagation()
																downloadDocument(id)
															}}
															title="Скачать"
														>
															<IconDownload size={20} color="#fff" />
														</button>
													</div>
												)}
											</div>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
				<div className="mt-2 ta-center">
					<Pagination lastPage={response?.lastPage} page={page} />
					{/* <ReactPaginate
						pageCount={response?.lastPage == 1 ? 0 : response?.lastPage}
						pageRangeDisplayed={5}
						marginPagesDisplayed={3}
						onPageChange={e => setPage(e.selected + 1)}
						activeClassName={"_active"}
						containerClassName={"pagination d-inline-flex ta-center"}
						pageLinkClassName={"link"}
						pageClassName={"link"}
						previousLabel={false}
						nextLabel={false}
					/> */}
				</div>
			</div>

			<Modal
				show={showModal}
				onClose={closeModal}
				modalStyles={{ maxWidth: 600 }}
			>
				{/* <div className="col-auto mb-3" style={{ display: "inline-block" }}>
					<label className="d-flex">
						<input
							type="checkbox"
							className="checkbox mr-2"
							checked={checkOnChecking}
							onChange={() => (
								setCheckOnChecking(!checkOnChecking), setIncomingNum("")
							)}
						/>
						<span>Отправка отчета на проверку</span>
					</label>
				</div> */}
				{/* {!checkOnChecking && (
					<>
						<div className="d-flex ">
							<span className="mt-auto mb-auto">ТР-</span>
							<Select
								fieldClasses="mt-auto mb-auto"
								onChange={e => setYearReportUpload(e.target.value)}
								value={yearReportUpload}
								values={[
									{
										value: new Date().getFullYear() - 1,
										label: new Date().getFullYear() - 1,
									},
									{
										value: new Date().getFullYear(),
										label: new Date().getFullYear(),
									},
									{
										value: new Date().getFullYear() + 1,
										label: new Date().getFullYear() + 1,
									},
								]}
							/>
							<span className="mt-auto mb-auto">-</span>
							<Input
								fieldClasses="mt-auto mb-auto"
								value={incomingNum}
								onChange={e => funcIncomingNum(e.target.value)}
							/>
						</div>
						{errObj.incNum && <span className="_error">{errObj.incNum}</span>}
					</>
				)} */}
				<div className="mt-3">
					<Dropzone
						maxSize={100000000}
						files={files}
						onFileRemove={onFileRemove}
						onFileUpload={onFileUpload}
						multiple={false}
						error={errObj.file}
					/>
					{errObj.file &&
            errObj.file.map(i => <div key={i} className="_error">{i}</div>
            )}
				</div>

				<button
					className="mt-4 btn btn-primary btn-block"
					onClick={() => uploadReport()}
				>
          Загрузить отчёт
				</button>
			</Modal>
		</div>
	)
}

export default ReportListContainer
