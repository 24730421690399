import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import LoginPage from "./Login"

const AuthFlow = () => {
	return (
		<Switch>
			<Route path="/" exact component={LoginPage} />
			
			<Redirect to="/" />
		</Switch>
	)
}

export default AuthFlow
