import PropTypes from "prop-types"
import React from "react"

function SvgComponent ({ size = 18, color = "#fff", ...props }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 612 612"
			height={size}
			width={size}
			fill={color}
			{...props}
		>
			<path
				d="M493.816 170.804H118.189c-11.367 0-20.582 9.214-20.582 20.58v321.509c0 54.648 44.461 99.107 99.105 99.107h218.574c54.646 0 99.107-44.459 99.107-99.107V191.384c.001-11.365-9.21-20.58-20.577-20.58zm-237.29 317.239c0 13.963-11.32 25.284-25.286 25.284-13.961 0-25.286-11.321-25.286-25.284V294.76c0-13.965 11.323-25.284 25.286-25.284 13.965 0 25.286 11.32 25.286 25.284v193.283zm149.521 0c0 13.963-11.32 25.284-25.286 25.284-13.961 0-25.286-11.321-25.286-25.284V294.76c0-13.965 11.323-25.284 25.286-25.284 13.965 0 25.286 11.32 25.286 25.284v193.283zM473.195 32.022h-89.654v-7.775C383.541 10.877 372.666 0 359.297 0H252.706c-13.37 0-24.247 10.877-24.247 24.247v7.775h-89.654c-34.33 0-62.261 27.929-62.261 62.26v39.924c0 7.14 5.788 12.929 12.931 12.929h433.054c7.138 0 12.927-5.789 12.927-12.929V94.282c0-34.33-27.929-62.26-62.261-62.26z" />
		</svg>
	)
}

SvgComponent.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default SvgComponent
