import PropTypes from "prop-types"
import React from "react"
import cancelImg from "../../../static/img/icon/cancel.svg"
import "./index.scss"

const Modal = ({
	show,
	modalTitle = "",
	onClose,
	modalStyles = {},
	children,
}) => {
	const closeClick = e => {
		e.target.classList.contains("modal") && onClose()
	}

	return (
		<div
			className={`modal scroll-y scroll-y-custom ${
				!show ? "d-none" : "d-block"
			}`}
			onClick={closeClick}
		>
			<div className="block d-flex flex-column" style={modalStyles}>
				<div className="block-head pr-5">
					{modalTitle}
					<button className="close" onClick={() => onClose()}>
						<img src={cancelImg} alt="" className="pos-abs image-contain" />
					</button>
				</div>

				<div className="block-content scroll-y d-flex flex-column flex-fill scroll-y-custom">
					{children}
				</div>
			</div>
		</div>
	)
}
Modal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	modalTitle: PropTypes.string,
	modalStyles: PropTypes.object,
	children: PropTypes.node.isRequired,
}

export default Modal
