export const REPORT_LIST = [
	{ value: "Отчет о деятельности МКК", label: "Отчет о деятельности МКК" },
	{
		value: "Отчет о средневзвешенных значениях ПСК",
		label: "Отчет о средневзвешенных значениях ПСК",
	},
	{
		value: "Отчет о персональном составе руководящих органов МФО",
		label: "Отчет о персональном составе руководящих органов МФО",
	},
]

export const REPORT_PERIOD = [
	{ value: "01.01.2020 - 31.03.2020", label: "01.01.2020 - 31.03.2020" },
	{ value: "01.04.2020 - 30.06.2020", label: "01.04.2020 - 30.06.2020" },
	{ value: "01.07.2020 - 30.09.2020", label: "01.07.2020 - 30.09.2020" },
	{ value: "01.10.2020 - 31.12.2020", label: "01.10.2020 - 31.12.2020" },
	{ value: "01.01.2020 - 31.12.2020", label: "01.01.2020 - 31.12.2020" },
]

export const REPORT_STATUS_LIST = [
	{ type: "white", text: "Не загружался" },
	{ type: "blue", text: "На проверке" },
	{ type: "pale-red", text: "Принят с ошибками" },
	{ type: "red", text: "Не удалось проверить на триггеры" },
	{ type: "green", text: "Принят без ошибок" },
	{ type: "dark-red", text: "Не принят БР" },
	{ type: "dark-red", text: "Отклонено" },
]
