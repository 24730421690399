import {
	UPDATE_ORGANIZATION,
	UPDATE_PROFILE,
	UPDATE_UNREAD_MESSAGES,
	UPLOADING_STATUS_PROGRESS,
} from "../types"

export const updateProfile = userData => {
	return dispatch => {
		localStorage.setItem("userData", JSON.stringify(userData))

		dispatch({
			type: UPDATE_PROFILE,
			payload: userData,
		})
	}
}

export const updateOrganization = organization => {
	return dispatch => {
		localStorage.setItem("organization", JSON.stringify(organization))

		dispatch({
			type: UPDATE_ORGANIZATION,
			payload: organization,
		})
	}
}

export const uploadingProgress = progress => {
	return dispatch => {
		dispatch({
			type: UPLOADING_STATUS_PROGRESS,
			payload: progress,
		})
	}
}

export const getUnReadMessages = res => {
	return dispatch => {
		dispatch({
			type: UPDATE_UNREAD_MESSAGES,
			payload: res,
		})
	}
}
