import React from "react"

const Accordeon = () => {
	return (
		<div className="accordeon-block mt-5">
			<button className="accordion d-flex jc-between">
				<div className="name">Вопрос 1</div>
				<div className="icon-accordeon">
					<img src="/static/img/icon/plus.svg" alt="" />
				</div>
			</button>
			<div className="panel">
				<p>Ответ на Вопрос 1...</p>
			</div>
			
			<button className="accordion d-flex jc-between">
				<div className="name">Вопрос 2</div>
				<div className="icon-accordeon">
					<img src="/static/img/icon/plus.svg" alt="" />
				</div>
			</button>
			<div className="panel">
				<p>Ответ на Вопрос 2...</p>
			</div>
			
			<button className="accordion d-flex jc-between">
				<div className="name">Вопрос 3</div>
				<div className="icon-accordeon">
					<img src="/static/img/icon/plus.svg" alt="" />
				</div>
			</button>
			<div className="panel">
				<p>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam id
					corporis perferendis maiores ratione ut quia, consectetur quas officia
					explicabo nisi quidem architecto hic vero, necessitatibus expedita?
					Fugit ad tempora dicta? Ex quaerat magni sint maiores sequi tempore
					praesentium alias obcaecati magnam reiciendis numquam commodi,
					aspernatur, ipsam minima iure placeat dolor illum neque! Id, expedita.
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel, ea
					eveniet odit veritatis dolore sunt doloribus, libero, dolor ab iure
					praesentium.
				</p>
			</div>
		</div>
	)
}

export default Accordeon
