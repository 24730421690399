import PropTypes from "prop-types"
import React from "react"
import "./index.scss"

const Badge = ({ type = "primary", children }) => {
	return <div className={`badge badge-${type}`}>{children}</div>
}
Badge.propTypes = {
	type: PropTypes.string,
	children: PropTypes.node,
}

export default Badge
