import PropTypes from "prop-types"
import React from "react"
import { useDropzone } from "react-dropzone"
import documentImg from "../../../static/img/icon/document.svg"
import { formatBytes } from "../../../utils/formatBytes"
import { IconTrash } from "../../icons"
import "./index.scss"

const Dropzone = ({
	maxSize,
	multiple = true,
	files,
	onFileRemove,
	onFileUpload,
	styles = {},
	acceptFileType = "",
	error = "",
}) => {
	const { getRootProps, getInputProps } = useDropzone({
		multiple,
		accept: acceptFileType,
		maxSize,
		onDrop: acceptedFiles => {
			onFileUpload(acceptedFiles)
		},
	})

	return (
		<div className="dropzone-wrap">
			<div
				{...getRootProps({
					className: "dropzone d-flex flex-column ai-center jc-center",
				})}
				style={styles}
			>
				<input
					{...getInputProps()}
					// accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .xls, .xlsx, .xtdd,.mp3"
				/>

				<h4 className="title">Перетащите файл или нажмите</h4>
			</div>

			<div className="dropzone-previews">
				{files.map(f => (
					<div key={f.path} className={`file pos ${error && "fail"}`}>
						<div className="row row-sm ai-center">
							<div className="col-auto">
								<div className="file-img pos">
									{f.type.match(/image\//) ? (
										<img
											className="image-cover"
											alt={f.name}
											src={URL.createObjectURL(f)}
										/>
									) : (
										<img
											src={documentImg}
											alt={f.name}
											className="image-contain doc-icon pos-abs"
										/>
									)}
								</div>
							</div>

							<div className="col font-700">
								<div className="file-name">{f.name}</div>
								<div className="file-size">{formatBytes(f.size)}</div>
							</div>
						</div>

						<button
							className="file-remove btn btn-danger pos-abs-y"
							onClick={() => onFileRemove(f.name)}
						>
							<IconTrash size={14} color="#fff" className="pos-abs" />
						</button>
					</div>
				))}
			</div>
		</div>
	)
}
Dropzone.propTypes = {
	maxSize: PropTypes.any.isRequired,
	multiple: PropTypes.any,
	files: PropTypes.any.isRequired,
	onFileRemove: PropTypes.any.isRequired,
	onFileUpload: PropTypes.any.isRequired,
	styles: PropTypes.object,
	acceptFileType: PropTypes.string,
	error: PropTypes.any,
}

export default Dropzone
