import PropTypes from "prop-types"
import * as React from "react"

const Blogging = ({ size = 15, color = "#fff", ...props }) => {
	return (
		<svg
			viewBox="0 0 512 512"
			width={size}
			height={size}
			fill={color}
			{...props}
		>
			<circle cx={256} cy={378.5} r={25} />
			<path d="M256 0a256 256 0 100 512 256 256 0 000-512zm0 472a216 216 0 110-432 216 216 0 010 432z" />
			<path d="M256 129c-44 0-80 35-80 80a20 20 0 1040 0 40 40 0 1140 40c-11 0-20 8-20 20v50a20 20 0 1040 0v-33a80 80 0 00-20-157z" />
		</svg>
	)
}

Blogging.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
}

export default Blogging
