import React, { useState } from "react"
import { apiGet } from "../../../api"
import { useFetch } from "../../../hooks/useFetch"
import document from "../../../static/img/icon/document copy.svg"
import { downloadBase64File } from "../../../utils/downloadBase64"
import { Modal, Spinner } from "../../components"
import "./index.scss"

const EventListContainer = () => {
	const { response, loading } = useFetch({ url: "/event" })

	const [eventData, setEventData] = useState(null)

	/**
	 * Чтение конкретного события
	 * @param {int} id - id события
	 */
	const getEventData = id => {
		apiGet({ url: `/event/view?id=${id}` }).then(res => {
			if (res.error) {
				console.error("Чтение конкретного события")
				return
			}

			setEventData(res.data || null)
		})
	}

	const downloadFile = id => {
		apiGet({ url: `/event/file?id=${id}`, timeout: 1000000 }).then(res => {
			if (res.error) {
				console.error("Скачивание файла (Мероприятия)")
				return
			}

			downloadBase64File(res.data.asBase64, res.data.name)
		})
	}

	return (
		<div className="page-event-list">
			<div className="card card__events">
				<div className="card-header">Мероприятия</div>

				<div className="card-content">
					{loading ? (
						<div className="d-flex jc-center">
							<Spinner />
						</div>
					) : (
						response?.events?.map(i => {
							return (
								<div key={i.period} className="mb-3">
									<div className="month h4 mb-2 f-500">{i.period}</div>

									{i.list.map(e => (
										<p
											key={e.id}
											className="event py-2"
											onClick={() => getEventData(e.id)}
										>
											<span className="h7 mb-1 d-block">{e.eventDate}</span>
											{e.title}
											<br />
											<div
												style={{ whiteSpace: "pre-line" }}
												className=" col-auto"
												dangerouslySetInnerHTML={{ __html: e?.content }}
											></div>
										</p>
									))}
								</div>
							)
						})
					)}
				</div>
			</div>

			<Modal
				modalStyles={{ maxWidth: 600 }}
				show={eventData !== null}
				onClose={() => setEventData(null)}
			>
				<div className="h4  mb-3">{eventData?.title || ""}</div>
				<div
					style={{ whiteSpace: "pre-line" }}
					className=" col-auto"
					dangerouslySetInnerHTML={{ __html: eventData?.content }}
				></div>
				<div className="mt-3">
					{eventData &&
						eventData.files.map((i, index) => (
							<div
								key={index}
								className={`file pos mb-1`}
								onClick={e =>
									e.target.className !== "file-remove btn btn-danger pos" &&
									downloadFile(i.id)
								}
							>
								<div className="row row-sm ai-center">
									<div className="col-auto">
										<div className="file-img pos">
											<img
												src={document}
												alt={i.name}
												className="image-contain doc-icon pos-abs"
											/>
										</div>
									</div>

									<div className="col font-700">
										<div className="file-name">{i.name}</div>
										<div className="file-size">{i.size}</div>
									</div>
								</div>
							</div>
						))}
				</div>
			</Modal>
		</div>
	)
}

export default EventListContainer
