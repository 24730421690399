import React from "react"
import { Link } from "react-router-dom"
import { useFetch } from "../../../hooks/useFetch"

const CalendarList = () => {
	const { response: getCalendar } = useFetch({ url: `/calendar` })

	return (
		<div className="page-event-list">
			<div className="row mb-2">
				<div className="col-auto">
					<a
						href="https://api.alliance-dev.ru/docs/algoritm.docx?v=2"
						className="btn btn-primary"
					>
						Алгоритм отправки отчетности
					</a>
				</div>
				
				{/*<div className="col-auto">*/}
				{/*	<a*/}
				{/*		href="https://lk.sro-mfo.ru/documents/%D0%90%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%20%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B8%20%D0%BE%D1%82%D1%87%D0%B5%D1%82%D0%B0%20%D0%9F%D0%A1%D0%9A%20%D0%B8%20%D0%9F%D0%A1.docx"*/}
				{/*		className="btn btn-primary"*/}
				{/*	>*/}
				{/*		Алгоритм отправки отчета ПСК и ПС*/}
				{/*	</a>*/}
				{/*</div>*/}
				
				<div className="col-auto">
					<Link to="/calendar-app" className="btn btn-primary">
						Программы для отчетов
					</Link>
				</div>
			</div>
			
			<div className="card card__events">
				<div className="card-header">Календарь событий</div>

				<div className="card-content">
					<div
						style={{ whiteSpace: "pre-line" }}
						className=" col-auto"
						dangerouslySetInnerHTML={{ __html: getCalendar }}
					/>
				</div>
			</div>
		</div>
	)
}

export default CalendarList
